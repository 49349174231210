:root {
  --color-blue-primary: #4A5E70;
  --color-blue-secondary: #638CC9;
  --color-blue-tertiary: #8BA4C9;
  --color-beige-primary: #C6A68A;
  --color-beige-secondary: #E8D4C3;
  --color-beige-tertiary: #F3E8DC;
  --color-error: #FF6F61;
  --white: #ffffff;
  --black: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color-primary: {
  color: var(--color-blue-primary);
}

.bg-blue-primary {
  background-color: var(--color-blue-primary) !important
}

.bg-blue-secondary {
  background-color: var(--color-blue-secondary) !important
}

.bg-blue-tertiary {
  background-color: var(--color-blue-tertiary) !important
}

.bg-beige-primary {
  background-color: var(--color-beige-primary) !important
}

.bg-beige-secondary {
  background-color: var(--color-beige-secondary) !important
}

.bg-beige-tertiary {
  background-color: var(--color-beige-tertiary) !important
}

.text-blue-primary {
  color: var(--color-blue-primary) !important
}

.text-blue-secondary {
  color: var(--color-blue-secondary) !important
}

.text-blue-tertiary {
  color: var(--color-blue-tertiary) !important
}

.text-beige-primary {
  color: var(--color-beige-primary) !important
}

.text-beige-secondary {
  color: var(--color-beige-secondary) !important
}

.text-beige-tertiary {
  color: var(--color-beige-tertiary) !important
}

.vertical-timeline::before {
  background: var(--color-blue-primary) !important;
}

.vertical-timeline-element-title, .vertical-timeline-element-subtitle, .vertical-timeline-element-content p {
  color: var(--color-blue-primary);
}

.vertical-timeline-element-content p {
  font-size: 1.2rem !important;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  border-top: 2px solid var(--color-beige-primary);
  border-right: 2px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-bottom: 2px solid var(--color-beige-secondary);
  border-left: 2px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.mainImage {
  background: url("./images/main-slider/slider1/slide1.webp") no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-top: 80px;
  height: 80vh;
}

@media only screen and (min-width: 768px) {
  .mainImage {
    background: url("./images/main-slider/slider1/slide1.webp") no-repeat center center fixed; 
    background-position-y: -80px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.nav-wrapper {
  text-align: center;
  margin-top: 10px;
}

.selectedLanguage {
  margin-right: 6px;
}

.sl-nav {
  display: inline;
}

.sl-nav ul {
  margin:0;
  padding:0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.sl-nav li {
  cursor: pointer;
  padding-bottom:10px;
}

.sl-nav li ul {
  display: none;
}

.sl-nav li:hover ul {
  position: absolute;
  top:29px;
  right:-15px;
  display: block;
  background: #fff;
  width: 140px;
  padding-top: 0px;
  z-index: 1;
  border-radius:5px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}

.sl-nav li:hover .triangle {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index:10;
  height: 14px;
  overflow:hidden;
  width: 30px;
  background: transparent;
}

.sl-nav li:hover .triangle:after {
  content: '';
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius:2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}

.sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom:0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
}

.sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}

.sl-nav li ul li span {
  padding-left: 5px;
}

.sl-nav li ul li span:hover, .sl-nav li ul li span.active {
  color: #146c78;
}

.sl-flag {
  display: inline-block;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.4);
  width: 15px;
  height: 15px;
  background: #aaa;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}

.flag-pt-pt {
background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABB5JREFUWIW9l39MlHUcx1/f7z13x91zckKmImjIjMOY6KKc9UejZIhlG7lg/SD/yK0/XCzSuVhbrZWFrs1qsbb+a2iy4XKT9WMrQv0nitaMiAQrIhVD5saRcL+f59sfxzGOO+TkId/bbd/n8/N93+fz/TzfjyBD9FPqkRgVErNKIe4DVk3/HIAfGFaInyVml0J8UcLgjUziioUMXmytWi9sqrHm0LUt+RfCD2XINwC0mcgj93Dh95sZavMpXvi43O705O4H803AEXHJ3gyTA7iBvRJzzwC+952EX1/PcCidoUwnbGyrLnR6cn4CdZj4FmM4hPsWCCRgBw5GcJz5jY15GRFoOLZjs2HGvgM2zZYbGoshAIBCbJOYPQP4ym5K4KXWynVI42sgha1hX9QOzEYB8NUgxflpCbzc/oDLsHEaWJnO27AL3SIBgDUKcfoyBa4UArGo/qqALfN5mlI4loAAQPkknqYkAvuO7dgAHFyiBAtCoA4kilICaNI4ADhvFwFAl5ivAYh97RUeW1QbAbLTWa7VwZcNxQMhcntCTJ0NEhmOLQWJKSBPk1H7TlApyXUN6oug1DstKMiCyixo8jLxWYBrb42jQsoKAV2gHpUCldJenRIaSmYlnw0p8Nbq5LesALlgJ18Ij2ggtkLyP6nMgzUuGGv2E/wxnOK17DE3uXuX4a1xM3FqygqBMg1UUtMRwLY74+vopRihXyMpXq7yeL16a3VLBBSiSAIrZgt1Dbz2ZEP387tZ/sk7OHdVJMmdJZZbg1cyZ/+THqZfsVbmI6tmO9qGdXFBon0pS0UYjw1cB9YlBIEYTETA6wBHkR0IEjrxOfKO5YQ7uoCEHMIDUav5JySI0dkSBXRfj69zntWR2ZJITx/+514hNnQF+1qN7Mfj3yV/u6UCRKCGpFLqh7mKzn9gJABankbhqVV4d+u47nWQU+/hrvaVSLdg6lyQfzsClggoRK9oOL79SYQ4OVepa/BMIWzKmaMwFf6TAcYOjaPClmugbsFWXOCOt2LfYIjc74NMnQsR+XtpWrGBtlp+VHd2UiiOz2d1JQDfjsIvHSHGWyeXKjkKcaKU/kkJoDTzKJD20vg/IWLDPAzTJ/rDp7r+FIh3b1d2hThazMWhGQIANvtkM3B+PidpqtSevDh0a8TemImbWLxX1x1UUuxSgpF0Xraosnbo47gqULV388fMFy7pVtzy9DdXMdmZjoQtqqwderisENU+LibFTpkLWuo7+2wxHkSopEnIFsMKgW4DbetGBvrmKtJORh/s6bwUvuG/H0QTEAHQFvcKIgLVbMN4uJT+0XQGC15pGtuqC2NGbP8Tb49tvoXhdEqgPhVwJFHt8yHjO1UvZbqLYIWJrAIS4/lq4vOfXyH+EqjzwBkD7ctS+iczifsf0SRU5Q6MVfkAAAAASUVORK5CYII=');
  background-size: cover;
  background-position: center center;
}

.flag-en-US {
  background-size: cover;
  background-position: center center;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII=');
}